import { Grid } from '@alirosoftware/web-components';
import React from 'react';
import { EmailPasswordLoginForm, EmailPasswordLoginFormProps } from './email-password-login-form';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FORGOT_PASSWORD_PATH, SIGNUP_PATH } from '../../../constants/routes';
import { SocialLinks } from '../social-links';

export type LoginModalProps = EmailPasswordLoginFormProps;

export const LoginModal = ({ onSuccess }: LoginModalProps) => {
  const { t } = useTranslation();

  return (
    <Grid.Container fluid>
      <Grid.Row>
        <Grid.Column colspan={12}>
          <SocialLinks journey="login" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column colspan={12}>
          <EmailPasswordLoginForm onSuccess={onSuccess} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column colspan={12} mt={4} textAlign="center">
          <p className="u-m-0">
            Don’t have an account?{' '}
            <Link href={SIGNUP_PATH} tabIndex={9} aria-label="Create new account" className="u-color-primary">
              <strong>Create new</strong>
            </Link>
          </p>

          <Link href={FORGOT_PASSWORD_PATH} tabIndex={10} aria-label={t('forgotPassword')}>
            {t('forgotPassword')}
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  );
};
