import React from 'react';
import { Box, Icon } from '@alirosoftware/web-components';
import { SOCIAL_LOGIN_FACEBOOK_PATH, SOCIAL_LOGIN_GOOGLE_PATH, SOCIAL_LOGIN_LINKED_PATH } from '../../constants/routes';
import { FacebookIcon, GoogleIcon, LinkedinIcon } from '../shared/icons';

export type SocialLinksProps = {
  journey: 'signup' | 'login';
};

export const SocialLinks = ({ journey }: SocialLinksProps) => {
  return (
    <Box>
      <p className="u-m-0 center">{journey === 'login' ? 'Log in' : 'Sign up'} with your social account</p>
      <Box display="flex" flexDirection="row" justifyContent="center" className="u-pull-center" flex={1}>
        <a
          href={SOCIAL_LOGIN_FACEBOOK_PATH}
          className="button button-icon button-social-icon"
          tabIndex={1}
          aria-label={`${journey === 'login' ? 'Log in' : 'Sign up'} with Facebook`}
        >
          <Icon icon={FacebookIcon} />
        </a>

        <a
          href={SOCIAL_LOGIN_GOOGLE_PATH}
          className="button button-icon button-social-icon"
          tabIndex={2}
          aria-label={`${journey === 'login' ? 'Log in' : 'Sign up'} with Google`}
        >
          <Icon icon={GoogleIcon} />
        </a>

        <a
          href={SOCIAL_LOGIN_LINKED_PATH}
          className="button button-icon button-social-icon"
          tabIndex={3}
          aria-label={`${journey === 'login' ? 'Log in' : 'Sign up'} with LinkedIn`}
        >
          <Icon icon={LinkedinIcon} />
        </a>
      </Box>

      <p className="u-m-0 center">
        {journey === 'login' ? 'or log in with your email and password' : 'or sign up with email'}
      </p>
    </Box>
  );
};
